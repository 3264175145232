<template>
    <div class="cs-block cs-calculation" :class="`${styleClass}${hasBg}`">
        <div class="cs-base-block">
            <div class="container">
                <div class="header-content">
                    <h2 class="cs-title" v-if="data.title">
                        <ColorSplit :data="data.title" />
                    </h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">{{ data.subTitle }}</h3>
                    <span class="cs-text" v-html="data.text"></span>
                </div>
                <CSCalculation :id="data.id" />
            </div>
        </div>
    </div>
</template>

<script>

import {computed} from '../libs/common-fn';

export default {
    name: 'Calculation',
    components: {
        CSCalculation: () => import('../components/CSCalculation.vue'),
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue")
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...computed('Calculation'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    },
    mounted(){
        console.log(this)
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";

.cs-block.cs-calculation {
    .header-content {
        margin-bottom: 30px;
        text-align: center;
    }
}

</style>
